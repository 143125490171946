<template>
  <div :style="widthCss" class="card">
    <div class="card-title">
      <div class="card-img-title">
        <img src="@/assets/icon/icon_e7fbmzzmou9/xinpin.png" style="width:20px;height:auto" alt="" />
        <span class="title"> 最新上线作品</span>
      </div>
      <div>
        <a-icon type="reload" @click="reload" />
      </div>
    </div>
    <a-spin :spinning="loading" class="card-content"  @click.stop>
      <a-row>
        <a-col :span="12" class="slick-col">
          <a-carousel
            v-if="pols.length && slickWidth != '0px'"
            arrows
            :afterChange="afterChange"
            ref="carousel"
            dotsClass="img-span"
            autoplay
            >
            <div slot="prevArrow" slot-scope="props" class="custom-slick-arrow" style="left: 10px;zIndex: 1">
              <a-icon type="left-circle" />
            </div>
            <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
              <a-icon type="right-circle" />
            </div>
            <div v-for="item in pols" :key="item.id">
              <div class="img-div">
                <img :src="item.cover" @click="openLink(item)" alt="" />
              </div>
            </div>
          </a-carousel>
        </a-col>
        <a-col class="product-title" v-if="pols[page]" :span="12">
          <div>
            <a-tooltip>
              <template slot="title">
                <div>{{ pols[page].onlineName || '' }}</div>
              </template>
              <div class="onlineName" @click="openLink(pols[page])">{{ pols[page].onlineName || '' }}</div>
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                <div>{{ pols[page].originName || '' }}</div>
              </template>
              <div class="originName">{{ pols[page].originName || '' }}</div>
            </a-tooltip>
          </div>
          <div class="product-detail" style="color:rgba(0,0,0,0.45)">
            <div>
              <span>上线平台</span>
              <span style="padding:0 3px 0 3px">：</span>
              <span>{{ pols[page].platform || '' }}</span>
            </div>
            <div>
              <span>上线时间</span>
              <span style="padding:0 3px 0 3px">：</span>
              <span>{{ (pols[page].onlineTime && pols[page].onlineTime.split(' ')[0]) || '' }}</span>
            </div>
            <div>
              <span>业务</span>
              <span style="padding:0 3px 0 3px">：</span>
              <span>{{ pols[page].businessTypes&&pols[page].businessTypes.map(item => item.value).join('，') || '' }}</span>
            </div>
          </div>
          <div style="padding-top:10px;">
            <div v-html="pols[page].detail" class="detail-span"></div>
          </div>
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script>
import { getAction } from '@/api/manage'
export default {
  props: {
    pols: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      page: 0,
      slickWidth: '0px'
    }
  },
  created() {},
  mounted() {
    setTimeout(() => {
      this.slickWidth = document.getElementsByClassName('slick-col')[0].clientWidth + 'px'
    }, 0)
    window.onresize = () => {
      this.slickWidth = document.getElementsByClassName('slick-col')[0].clientWidth + 'px'
    }
  },
  computed: {
    widthCss() {
      return {
        '--slick-width': this.slickWidth
      }
    }
  },

  beforeDestroy() {},
  methods: {
    afterChange(e) {
      this.page = e
    },
    openLink(item) {
      if (item.link) {
        window.open(item.link)
      }
    },
    reload() {
      this.$emit('getFirstData')
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@assets/less/homeStyle.less';
.img-div {
  width: 100%;
  height: 300px;
  position: relative;
  cursor: pointer;
  img {
    position: absolute;
    height: auto;
    width:100%;
    min-width: 200px;
    min-height: 300px;
  }
}
.card-content {
  padding: 32px 30px 32px 40px !important;
}
.detail-span {
  /*   -webkit-column-count: 2; 
  -moz-column-count: 2; 
  -webkit-column-fill:auto;
  column-fill:auto;
  column-count: 2; 分列 */
  height: 174px;
  overflow-y: auto;
  line-height: 20px;
  font-size: 12px;
}
.onlineName {
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  &:hover {
    color: var(--theme-color);
  }
}
.originName {
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.detail-span::-webkit-scrollbar-button {
  height: 0;
}
.detail-span::-webkit-scrollbar {
  border-radius: 0;
  width: 3px;
}
.detail-span::-webkit-scrollbar-track {
  border-radius: 0;
}
.detail-span::-webkit-scrollbar-track-piece {
  border-radius: 0;
}
.detail-span::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #c1c1c1;
}
/deep/ .img-span {
  li {
    button {
      width: 8px !important;
      height: 8px !important;
      border-radius: 50% !important;
    }
  }
}
/deep/ .ant-carousel .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff !important;
  /* background-color: rgba(31, 45, 61, 0.11); */
  opacity: 0;
}
/* /deep/ .ant-carousel .custom-slick-arrow:before {
  display: none;
} */
/deep/ .ant-carousel :hover {
  .custom-slick-arrow {
    opacity: 1;
    
    &:hover {
      color: var(--theme-color) !important;
    }
  }
}
/deep/ .ant-carousel .slick-next::before {
    content: '' !important;
}
/deep/ .ant-carousel .slick-prev::before {
    content: '' !important;
}
</style>
