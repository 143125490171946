var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card", style: _vm.widthCss },
    [
      _c("div", { staticClass: "card-title" }, [
        _vm._m(0),
        _c(
          "div",
          [
            _c("a-icon", {
              attrs: { type: "reload" },
              on: { click: _vm.reload },
            }),
          ],
          1
        ),
      ]),
      _c(
        "a-spin",
        {
          staticClass: "card-content",
          attrs: { spinning: _vm.loading },
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { staticClass: "slick-col", attrs: { span: 12 } },
                [
                  _vm.pols.length && _vm.slickWidth != "0px"
                    ? _c(
                        "a-carousel",
                        {
                          ref: "carousel",
                          attrs: {
                            arrows: "",
                            afterChange: _vm.afterChange,
                            dotsClass: "img-span",
                            autoplay: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "prevArrow",
                                fn: function (props) {
                                  return _c(
                                    "div",
                                    {
                                      staticClass: "custom-slick-arrow",
                                      staticStyle: {
                                        left: "10px",
                                        zIndex: "1",
                                      },
                                    },
                                    [
                                      _c("a-icon", {
                                        attrs: { type: "left-circle" },
                                      }),
                                    ],
                                    1
                                  )
                                },
                              },
                              {
                                key: "nextArrow",
                                fn: function (props) {
                                  return _c(
                                    "div",
                                    {
                                      staticClass: "custom-slick-arrow",
                                      staticStyle: { right: "10px" },
                                    },
                                    [
                                      _c("a-icon", {
                                        attrs: { type: "right-circle" },
                                      }),
                                    ],
                                    1
                                  )
                                },
                              },
                            ],
                            null,
                            false,
                            2704007100
                          ),
                        },
                        _vm._l(_vm.pols, function (item) {
                          return _c("div", { key: item.id }, [
                            _c("div", { staticClass: "img-div" }, [
                              _c("img", {
                                attrs: { src: item.cover, alt: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openLink(item)
                                  },
                                },
                              }),
                            ]),
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.pols[_vm.page]
                ? _c(
                    "a-col",
                    { staticClass: "product-title", attrs: { span: 12 } },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.pols[_vm.page].onlineName || "")
                                  ),
                                ]),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "onlineName",
                                  on: {
                                    click: function ($event) {
                                      return _vm.openLink(_vm.pols[_vm.page])
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.pols[_vm.page].onlineName || "")
                                  ),
                                ]
                              ),
                            ],
                            2
                          ),
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.pols[_vm.page].originName || "")
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "originName" }, [
                                _vm._v(
                                  _vm._s(_vm.pols[_vm.page].originName || "")
                                ),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "product-detail",
                          staticStyle: { color: "rgba(0,0,0,0.45)" },
                        },
                        [
                          _c("div", [
                            _c("span", [_vm._v("上线平台")]),
                            _c(
                              "span",
                              { staticStyle: { padding: "0 3px 0 3px" } },
                              [_vm._v("：")]
                            ),
                            _c("span", [
                              _vm._v(_vm._s(_vm.pols[_vm.page].platform || "")),
                            ]),
                          ]),
                          _c("div", [
                            _c("span", [_vm._v("上线时间")]),
                            _c(
                              "span",
                              { staticStyle: { padding: "0 3px 0 3px" } },
                              [_vm._v("：")]
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  (_vm.pols[_vm.page].onlineTime &&
                                    _vm.pols[_vm.page].onlineTime.split(
                                      " "
                                    )[0]) ||
                                    ""
                                )
                              ),
                            ]),
                          ]),
                          _c("div", [
                            _c("span", [_vm._v("业务")]),
                            _c(
                              "span",
                              { staticStyle: { padding: "0 3px 0 3px" } },
                              [_vm._v("：")]
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  (_vm.pols[_vm.page].businessTypes &&
                                    _vm.pols[_vm.page].businessTypes
                                      .map((item) => item.value)
                                      .join("，")) ||
                                    ""
                                )
                              ),
                            ]),
                          ]),
                        ]
                      ),
                      _c("div", { staticStyle: { "padding-top": "10px" } }, [
                        _c("div", {
                          staticClass: "detail-span",
                          domProps: {
                            innerHTML: _vm._s(_vm.pols[_vm.page].detail),
                          },
                        }),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-img-title" }, [
      _c("img", {
        staticStyle: { width: "20px", height: "auto" },
        attrs: {
          src: require("@/assets/icon/icon_e7fbmzzmou9/xinpin.png"),
          alt: "",
        },
      }),
      _c("span", { staticClass: "title" }, [_vm._v(" 最新上线作品")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }