import { render, staticRenderFns } from "./productCarousel.vue?vue&type=template&id=f250ea46&scoped=true&"
import script from "./productCarousel.vue?vue&type=script&lang=js&"
export * from "./productCarousel.vue?vue&type=script&lang=js&"
import style0 from "./productCarousel.vue?vue&type=style&index=0&id=f250ea46&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f250ea46",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/jobs/management-system-frontend/workspace/ant-design-vue-jeecg/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f250ea46')) {
      api.createRecord('f250ea46', component.options)
    } else {
      api.reload('f250ea46', component.options)
    }
    module.hot.accept("./productCarousel.vue?vue&type=template&id=f250ea46&scoped=true&", function () {
      api.rerender('f250ea46', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/account/center/page/productCarousel.vue"
export default component.exports